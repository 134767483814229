<template>
  <div :class="['login-feedback', { '-bpo': this.appType === 'bpo' }]">
    <img :src="icon" class="icon" />

    <div class="title-wrapper">
      <c-title
        class="title"
        size="2"
        center
      >
        {{ title }}
      </c-title>
    </div>

    <p class="info">
      {{ info }}
    </p>

    <c-button
      v-if="buttonMessage"
      class="action"
      type="submit"
      primary
      @click="$emit('submit')"
    >
      {{ buttonMessage }}
    </c-button>
  </div>
</template>

<script>
import LoginFeedbacks from '@content/Feedbacks'

import { mapGetters } from 'vuex'
import * as types from '@store/types'

export default {
  props: {
    appType: {
      type: String,
      validator: appType => {
        const types = ['rh', 'bpo']
        return types.includes(appType)
      }
    }
  },

  computed: {
    ...mapGetters({
      content: types.LOGIN_CONTENT,
      feedback: types.LOGIN_FEEDBACK
    }),
    icon () {
      return require('@assets/' + LoginFeedbacks[this.content][this.feedback].icon + '.svg')
    },
    info () {
      return LoginFeedbacks[this.content][this.feedback].text
    },
    title () {
      return LoginFeedbacks[this.content][this.feedback].title
    },
    buttonMessage () {
      return LoginFeedbacks[this.content][this.feedback].button
    }
  }
}
</script>

<style lang="scss">
.login-feedback {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding: 60px 20px 20px;
  min-height: 256px;

  & > .icon { height: 110px; }

  & > .title-wrapper {
    margin-top: 30px;

    & > .title {
      opacity: .8;

      & > .text { color: #121E48 }

      & > .border {
        background: set-linear-gradient(horizontal, (light: var(--color-a), dark:  var(--color-b)));
      }
    }
  }

  & > .info {
    margin-top: 20px;
    font-size: 16px;
    text-align: center;
  }

  & > .action {
    width: 160px;
    margin: 30px 0;
    background: set-linear-gradient(135deg, (light: var(--color-a), dark:  var(--color-b)));
    & > .border { border-color: var(--color-a) }
    &::before {background: set-linear-gradient(135deg, (light: var(--color-a), dark:  var(--color-b))) }
  }

  @include tablet(true) {
    & > .icon { height: 130px; }

    & > .title { margin-top: 40px; }

    & > .info {
      width: 414px;
      margin-top: 20px;
      font-size: 16px;
    }

    & > .action {
      width: 180px;
      height: 60px !important;
      max-height: 60px !important;
      min-height: 60px !important;
      margin-top: 35px;
    }
  }

  &.-bpo {
    --color-a: #{map-get($primary-color-map, light)};
    --color-b: #{map-get($primary-color-map, dark)};
  }
}
</style>
