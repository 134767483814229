export default {
  signin: {
    email: {
      type: 'text',
      label: 'e-mail',
      validation: {
        regex: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        required: true
      },
      validationMsg: 'Digite um endereço de e-mail válido.',
      jumbo: true,
      value: '',
      autocomplete: 'nope'
    },
    password: {
      type: 'password',
      label: 'senha',
      validation: 'required',
      jumbo: true,
      value: '',
      autocomplete: 'new-password'
    }
  },
  passwordRecovery: {
    email: {
      type: 'text',
      label: 'e-mail',
      validation: {
        regex: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        required: true
      },
      validationMsg: 'Digite um endereço de  e-mail válido.',
      jumbo: true,
      value: '',
      autocomplete: 'nope'
    }
  },
  passwordReset: {
    password: {
      type: 'password',
      label: 'nova senha',
      validation: {
        regex: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])([a-zA-Z0-9 !"#$%&'()*+,-./:;<=>?@[\\\]\^_`{|}~]{6,})$/,
        required: true
      },
      validationMsg: 'Sua senha deve ter no mínimo 8 caracteres, utilizando letras maiúsculas, minúsculas e números.',
      jumbo: true,
      value: '',
      autocomplete: 'new-password'
    },
    repeatedPassword: {
      type: 'password',
      label: 'repita a nova senha',
      validation: {
        custom: formData => repeatedPassword => (formData || {}).password === repeatedPassword,
        required: true
      },
      validationMsg: 'Digite a mesma senha.',
      jumbo: true,
      value: '',
      autocomplete: 'new-password'
    }
  }
}
