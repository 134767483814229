<template lang="html">
  <div :class="classes">
    <c-title
      class="title"
      size="1"
      center
      dark-text
    >
      {{ title }}
    </c-title>

    <p class="info">
      {{ info }}
    </p>

    <c-form
      class="form"
      no-listeners
      :key="content"
      :fields="formFields"
      :loading="loadingButton"
      @submit="onSubmit"
    >
      <template slot="actions">
        <!-- KEEP CONNECTED OPTION IS DISABLED FOR NOW -->
        <c-selectable
          class="keep-connected"
          v-if="false && oAuth2Condition"
          :selected="keepConnected"
          position="left"
          @toggle="keepConnected = !keepConnected"
        >
          <span class="text">
            Manter-me conectado
          </span>
        </c-selectable>

        <p class="hint">
          {{ pHint }}
          <a class="link" @click="$emit(busy ? '' : 'toggle')">
            {{ aHint }}
          </a>
        </p>

        <c-button
          class="action"
          type="submit"
          :disabled="busy"
          :loading="loadingButton"
        >
          {{ buttonMessage }}
        </c-button>

        <p v-if="jwtCondition" class="recaptcha-warning">
          Este site é protegido pelo reCAPTCHA e o
          <a href="https://policies.google.com/privacy" target="_blank">Google Privacidade & Termos</a> e
          <a href="https://policies.google.com/terms" target="_blank">Termos de Serviço</a> se aplicam.
        </p>
      </template>
    </c-form>
  </div>
</template>

<script>
import FormFields from '@content/Forms'

import MediaQuery from '@mixins/MediaQuery'

import { mapGetters } from 'vuex'
import * as types from '@store/types'

export default {
  mixins: [ MediaQuery ],

  data: () => ({
    keepConnected: false
  }),

  props: {
    content: String,
    appType: {
      type: String,
      validator: appType => {
        const types = ['rh', 'bpo']
        return types.includes(appType)
      }
    },
    loginType: {
      type: String,
      validator: appType => {
        const types = ['jwt', 'oAuth2']
        return types.includes(appType)
      }
    },
    loadingButton: Boolean
  },

  computed: {
    ...mapGetters({
      busy: types.LOGIN_BUSY,
      LoginTexts: types.SETTINGS_TEXTS
    }),

    info () {
      return this.LoginTexts[this.content].text
    },
    formFields () {
      return FormFields[this.content]
    },
    title () {
      return this.LoginTexts[this.content].title
    },
    buttonMessage () {
      return this.LoginTexts[this.content].actions.button
    },
    pHint () {
      return this.LoginTexts[this.content].actions.p
    },
    aHint () {
      return this.LoginTexts[this.content].actions.a
    },
    oAuth2Condition () {
      return this.content === 'signin' &&
      this.loginType === 'oAuth2'
    },
    jwtCondition () {
      return this.content === 'signin' &&
      this.loginType === 'jwt'
    },
    classes () {
      return ['login-form', {
        '-signin': this.content === 'signin',
        '-password-recovery': this.content === 'passwordRecovery',
        '-bpo': this.appType === 'bpo'
      }]
    }
  },

  methods: {
    onSubmit (data) {
      Object.assign(data, { keep_connected: this.keepConnected })
      this.$emit('submit', data)
    }
  }
}
</script>

<style lang="scss">
.login-form {
  & > .title > .border {
    background: set-linear-gradient(horizontal, (light: var(--color-a), dark:  var(--color-b)));
  }

  @include xs-mobile {
    & > .form > .fields > .field.-jumbo.-validation.password:first-child {
      margin-bottom: 72px;

      & > .validation {
        overflow: unset;

        & > .message > .text {
          overflow: unset;
          white-space: unset;
        }
      }
    }
  }

  & > .form > .actions {
    & > .hint { text-align: center }
    & > .action {
      background: set-linear-gradient(135deg, (light: var(--color-a), dark:  var(--color-b)));
      & > .border { border-color: var(--color-a) }

      &::before {
        background: set-linear-gradient(135deg, (light: var(--color-a), dark:  var(--color-b)));
        box-shadow: 0 1px 20px var(--color-a);
      }

      &:hover::before { box-shadow: 0 5px 30px 5px var(--color-a); filter: blur(14px); }
    }
    & > .recaptcha-warning {
      font-size: 12px;
      text-align: center;
    }
    & > .keep-connected { margin-bottom: 30px; }
    & > .keep-connected > .text {
      padding-left: 10px;
      color: map-get($text-color, base-80);
    }
  }

  a {
    border-bottom: 1px dotted;
    font-weight: bold;
    color: $base-text-color;
    letter-spacing: $short-letter-spacing;
    line-height: 22px;
    opacity: .7;

    &:hover { opacity: .8 }
  }

  @include tablet(true) {
    &.-signin > .info { @include hide; }
  }

  &.-bpo {
    --color-a: #{map-get($primary-color-map, light)};
    --color-b: #{map-get($primary-color-map, dark)};
  }
}
</style>
