<template lang="html">
  <div :class="classes">
    <div class="content">
      <login-feedback
        v-if="feedback"
        v-bind="$attrs"
        v-on="$listeners"
      />

      <login-form
        v-else
        :content="delayedContent"
        v-bind="$attrs"
        v-on="$listeners"
      />
    </div>

    <div class="aside">
      <div class="right">
        <div class="text-wrapper">
          <p class="title">{{ rightInfo.title }}</p>
          <p class="text">{{ rightInfo.text }}</p>
        </div>
      </div>

      <div class="left">
        <div class="text-wrapper">
          <p class="title">{{ leftInfo.title }}</p>
          <p class="text">{{ leftInfo.text }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoginForm from '@components/login/Form'
import LoginFeedback from '@components/login/Feedback'

import { mapGetters, mapActions } from 'vuex'
import * as types from '@store/types'

import isMS from '@modules/isMS'

export default {
  components: {
    LoginForm,
    LoginFeedback
  },

  props: {
    content: String
  },

  data () {
    return {
      isMS,
      delayedContent: ''
    }
  },

  computed: {
    ...mapGetters({
      feedback: types.LOGIN_FEEDBACK,
      fresh: types.LOGIN_FRESH,
      LoginTexts: types.SETTINGS_TEXTS
    }),
    componentState () {
      if (this.feedback) return '-feedback'
      return {
        '-fresh': this.fresh,
        '-signin': this.delayedContent === 'signin' || (this.content === 'signin' && !this.fresh),
        '-password -recovery': this.content === 'passwordRecovery',
        '-password -reset': this.content === 'passwordReset'
      }
    },
    leftInfo () {
      return this.LoginTexts.signin.info
    },
    rightInfo () {
      return this.delayedContent === 'signin'
        ? this.LoginTexts.passwordRecovery.info
        : this.LoginTexts[this.delayedContent].info
    },
    classes () {
      return [ 'desktop-login',
        this.componentState,
        { '-no-animation': this.isMS }
      ]
    }
  },

  methods: mapActions({ updateFresh: types.LOGIN_UPDATE_FRESH }),

  watch: {
    content (val) {
      if (this.fresh) this.updateFresh(false)

      setTimeout(() => { this.delayedContent = val }, 400)
    }
  },

  created () {
    this.delayedContent = this.content
  }
}
</script>

<style lang="scss">
.desktop-login {
  position: relative;
  width: 740px;

  height: 536px;
  &[app-type='bpo'] { height: 492px;}

  border-radius: 8px;
  background: linear-gradient(347.66deg, #FFFFFF 0%, rgba(255,255,255,0.83) 100%);
  box-shadow: 0 6px 30px 0 rgba(0,0,0,0.1);
  overflow: hidden;
  align-self: center;

  & > .aside {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../../assets/pattern_login.svg'),
                      set-linear-gradient(diagonal, (light: var(--color-a), dark: var(--color-b)));
    background-blend-mode: overlay;
    display: flex;
    justify-content: space-between;
    pointer-events: none;
    border-radius: 8px;

    & > .left,
    & > .right {
      width: 280px;
      height: 100%;
      padding: 50px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      transition: transform .9s, opacity .8s;

      & > .text-wrapper {
        border-radius: 15px;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        position: relative;

        & > .title {
          margin: 0 -20px;
          font-family: $title-font-family;
          font-size: 28px;
          font-weight: bold;
          line-height: 42px;
          color: #FFFFFF;
          z-index: $z-index-1;
        }

        & > .text {
          margin-top: 17px;
          letter-spacing: $short-letter-spacing;
          line-height: 22px;
          color: #FFFFFF;
          max-width: 100%;
          z-index: $z-index-1;
        }
      }
    }

    & > .right > .text-wrapper::before,
    & > .left > .text-wrapper::before {
      content: '';
      display: block;
      height: 100%;
      width: 100%;
      position: absolute;
      filter: blur(20px);
    }

    & > .right > .text-wrapper::before {
      background-image: linear-gradient(135deg, var(--color-a) -80%, var(--color-b) 340%);
    }

    & > .left > .text-wrapper::before {
      background-image: linear-gradient(135deg, var(--color-a) -239%, var(--color-b) 180%);
    }
  }

  & > .content {
    width: 460px;
    height: 100%;
    padding: 64px 80px 60px 80px;
    transition: margin-left .8s ease-in-out;
  }

  & > .content > .login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  & > .content > .login-form > .title > .text {
    color: $base-text-color;
    font-weight: bold;
    opacity: .8;
  }

  & > .content > .login-form > .info {
    font-size: 16px;
    letter-spacing: $shorter-letter-spacing;
    line-height: 22px;
    text-align: center;
    max-width: 300px;
    width: 100%;
    margin: 0 auto;
    margin-top: 30px;
  }

  & > .content > .login-form > .form {
    width: 100%;
    margin-top: 30px;
  }

  & > .content > .login-form > .form > .actions {
    flex-direction: column;
    margin-top: 30px;

    & > .hint { margin: 0 auto; }

    & > .action {
      margin: 0 auto;
      margin-top: 43px;
      width: 200px;
      max-width: 200px;
      height: 60px !important;
      min-height: 60px !important;
      max-height: 60px !important;

      & > .text { font-size: 13px; }
    }
  }

  &.-signin {
    & > .content {
      margin-left: 0;
      padding: 64px 80px 20px 80px;

      & > .login-form > .form > .actions > .recaptcha-warning { margin-top: 40px }
    }
    & > .aside {
      animation: aside-backwards .8s;
      animation-fill-mode: both;
    }
    & > .aside > .right {
      transform: translateX(-50px);
    }
    & > .aside > .left {
      transition: transform .9s, opacity 1.5s;
    }
  }

  &.-password {
    &.-recovery {
      & > .content { margin-left: 280px; }
      & > .aside {
        animation: aside-forward .8s;
        animation-fill-mode: both;

        & > .left {
          transform: translateX(50px);
        }

        & > .right {
          transform: translateX(0);

          & > .text-wrapper {
            width: 200px;
            height: 160px;
          }
        }
      }
    }

    &.-reset {
      height: 492px;

      & > .content {
        margin-left: 280px;
        transition-property: none;

        & > .login-form > .form > .actions {
          margin-top: 25px;

          & > .action { margin-top: 0; }
        }
      }
    }
  }

  &.-fresh {
    & > .content { transition-property: none }
    & > .aside {
      animation: none;
      clip-path: inset(0 0 0 62%);
    }

    &.-password {
      & > .aside {
      animation: none;
        clip-path: inset(0 62% 0 0);
      }
    }
  }

  &.-feedback {
    & > .aside { @include hide }
    & > .content {
      width: 100%;
      transition-property: none;
      display: flex;
      justify-content: center;
      align-items: center;

      & > .login-form {
        & > .title { margin-bottom: 18px; }
        & > .info {
          max-width: 400px;
          margin-top: 0;
          text-align: center;
          font-size: 16px;
        }
        & > .form > .actions {
          margin-top: 5px;

          & > .action {
            width: 180px;
            margin-top: 0;
          }
        }
      }
    }
  }

  &.-no-animation {
    & > .aside {
      width: calc(100% - 62%);
    }

    &.-signin,
    &.-fresh {
      & > .aside {
        left: auto;
        right: 0;

        & > .right { display: none; }
      }
    }

    &.-password > .aside {
      & > .left { display: none; }
    }
  }

  // animations:
  @keyframes aside-forward {
    0% {
      animation-timing-function: ease-in;
      clip-path: inset(0 0 0 62%);
      background-position-x: 0px, 0px;
    }
    50%  {
      animation-timing-function: ease-out;
      clip-path: inset(0 22% 0 22%);
      background-position-x: -25px , 0px;
    }
    100% {
      clip-path: inset(0 62% 0 0);
      background-position-x: -50px, 0px;
    }
  }

  @keyframes aside-backwards {
    0% {
      animation-timing-function: ease-in;
      clip-path: inset(0 62% 0 0);
      background-position-x: -50px, 0px;
    }
    50%  {
      animation-timing-function: ease-out;
      clip-path: inset(0 22% 0 22%);
      background-position-x: -25px , 0px;
    }
    100% {
      clip-path: inset(0 0 0 62%);
      background-position-x: 0px, 0px;
    }
  }
}
</style>
